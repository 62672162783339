var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.stat)?_c('div',[_c('div',{staticClass:"separator"}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{attrs:{"to":'/lk/seminars/' + _vm.stat.event.id}},[_c('img',{attrs:{"src":"/pic/profile/icon-back.svg"}})]),_c('h3',{staticClass:"m-0"},[_vm._v("Статистика по мероприятию")])],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('a',{staticClass:"link-underline hidden-md hidden-sm",attrs:{"target":"_blank","href":_vm.apiUrl + 'statistic-event/' + _vm.stat.event.id + '?access_token=' + _vm.token}},[_vm._v("Выгрузить статистику")]),_vm._v("  "),_c('img',{attrs:{"src":"/pic/profile/icon-download-stat.svg"}})])]),_c('seminar-stat-row',{attrs:{"stat":{
    count_invites: _vm.stat.event.count_invites,
    count_participants: _vm.stat.event.count_participants,
    participants: _vm.stat.participants.filter(participant => participant.status === 'approved').length,
    test_solved_count: _vm.stat.test_solved_count,
    cert_count: _vm.stat.cert_count
  }}}),_c('div',{staticClass:"separator"}),_c('seminar-company-stat-list',{attrs:{"items":_vm.stat.participants}}),_c('div',{staticClass:"separator"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }