<template>
  <div v-if="stat">
    <div class="separator"></div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <router-link :to="'/lk/seminars/' + stat.event.id">
          <img src="/pic/profile/icon-back.svg" />
        </router-link>
        <h3 class="m-0">Статистика по мероприятию</h3>
      </div>
      <div class="d-flex align-items-center">
        <a target="_blank" :href="apiUrl + 'statistic-event/' + stat.event.id + '?access_token=' + token" class="link-underline hidden-md hidden-sm">Выгрузить статистику</a>&nbsp;
        <img src="/pic/profile/icon-download-stat.svg" />
      </div>
    </div>
    <seminar-stat-row :stat="{
      count_invites: stat.event.count_invites,
      count_participants: stat.event.count_participants,
      participants: stat.participants.filter(participant => participant.status === 'approved').length,
      test_solved_count: stat.test_solved_count,
      cert_count: stat.cert_count
    }" />
    <div class="separator"></div>
    <seminar-company-stat-list :items="stat.participants" />
    <div class="separator"></div>
  </div>
</template>

<script>
import ApiService from "../../../../services/api.service";
import SeminarStatRow from "@/components/profile/seminar/SeminarStatRow.vue";
import SeminarCompanyStatList from "@/components/profile/stat/SeminarCompanyStatList.vue";
import {TokenService} from "../../../../services/token.service";

export default {
  name: "SeminarStat",
  components: {SeminarCompanyStatList, SeminarStatRow},
  data() {
    return {
      stat: null,
      apiUrl: process.env.VUE_APP_ROOT_API,
      token: TokenService
          .getToken()
          .replaceAll('"', '')
    }
  },
  async mounted() {
    let res = await ApiService.get('events/' + this.$route.params.id + '/statistic')
    this.stat = res.data
  }
}
</script>

<style scoped>

</style>