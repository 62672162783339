<template>
  <div>
    <div v-for="(question, key) in questions" :key="key" class="test__item">
      <div class="test__item-title mb-2 mt-2" v-html="question.title"></div>
      <div class="form-check test__item-question">
        <template v-if="!question.multiple && !question.typed">
          <div class="test__item-question-item form-radio radio" v-for="(answer, indexKey) in question.answers" :key="indexKey" :class="{correct: answer.correct && answer.correct === true, incorrect: answer.correct === false}">
            <input disabled :checked="question.user.answer == answer.id" type="radio" :name="question.id" :value="answer.id" :id="key+'_'+indexKey" />
            <label :for="key+'_'+indexKey" v-html="answer.title"></label>
          </div>
        </template>
        <template v-else-if="question.multiple">
          <div class="test__item-question-item form-checkbox" v-for="(answer, indexKey) in question.answers" :key="indexKey" :class="{correct: answer.correct && answer.correct === true, incorrect: answer.correct === false}">
            <div class="form-checkbox__wrapper">
              <input type="checkbox" disabled :checked="question.user.answer.split(',').indexOf(answer.id.toString()) > -1" :value="answer.id" :id="key+'_'+indexKey" />
              <label class="d-inline-block" :for="key+'_'+indexKey" v-html="answer.title"></label>
            </div>
          </div>
        </template>
      </div>
      <div class="separator"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestQuestionResult",
  props: {
    questions: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>