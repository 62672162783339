<template>
  <div class="event-stat row">
    <div class="col-lg col-6" v-for="key in Object.keys(stat)" :key="key" :class="{'col-lg-3': key === 'cert_count'}">
      <div class="event-stat__item">
        <div class="event-stat__item__icon mr-1">
          <img :src="'/pic/stats/' + key + '.svg'" />
        </div>
        <div>
          {{ stat[key] }}
          <div class="event-stat__item__text">
            <template v-if="key === 'cert_count'">
              Получили сертификаты
            </template>
            <template v-if="key === 'count_invites'">
              {{ stat[key] | pluralize('ru', ['Приглашение', 'Приглашения', 'Приглашений']) }}
            </template>
            <template v-if="key === 'count_participants'">
              {{ stat[key] | pluralize('ru', ['Заявка', 'Заявки', 'Заявок']) }}
            </template>
            <template v-if="key === 'participants'">
              {{ stat[key] | pluralize('ru', ['Одобрена', 'Одобрено', 'Одобрено']) }}
            </template>
            <template v-if="key === 'test_solved_count'">
              {{ stat[key] | pluralize('ru', ['Сданный тест', 'Сданных теста', 'Сданных тестов']) }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeminarStatRow",
  props: {
    stat: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "../../../assets/style/variables.scss";
.event-stat {
  margin: 6.4rem 0 0;
  &__item {
    font-size: 3.2rem;
    color: $blue-03;
    display: flex;
    justify-content: flex-start;
    line-height: 1;
    &__icon {
      margin-top: -3px;
      img {
        width: 3.2rem;
      }
    }
    &__text {
      color: $blue-0;
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
}
</style>